import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom"; //gives ability to link to relevant page
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CodeIcon from '@mui/icons-material/Code';
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";

const Item = ({title, to, icon, selected, setSelected}) => {
    // Used by all menu items to set up route and style behavior
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[200],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}
const Sidebar = () => {
  // Set up sidebar theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Set state of sidebar
  const [isCollapsed, setIsCollapsed] = useState(false);
  // Represents the currently selected page
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        // Override default CSS for sidebar
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#94e2cd !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* Logo and Menu Icon    */}
          <MenuItem
            // Uncollapse on click
            onClick={() => setIsCollapsed(!isCollapsed)}
            // If menu is collapsed, set menu icon
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {/* // If menu is not collapsed, show CDO and Icon */}
            {!isCollapsed && (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>
                        CDO Operations
                    </Typography>
                    <IconButton>
                        <MenuOutlinedIcon />
                    </IconButton>
                </Box>
            )}
          </MenuItem>
          {/* If uncollapsed, add padding 10% */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {/* Add side bar items */}
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography> */}
            {/* <Item
              title="Contact Info"
              to="/contact"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Releases"
              to="/releases"
              icon={<CodeIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
