import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
// import {LineChart} from '@mui/x-charts/LineChart';
import {SparkLineChart} from '@mui/x-charts/SparkLineChart'
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const MonitorChart = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Create state to store the response from API call
    const [responseData, setResponseData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState(null);

    const fetchDataHandler = useCallback(async () => {
        try {
            // Include props data in the request parameters
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dynatrace/metrics`, { params: {productId: props.prodId} });
            // Set the values from the response
            setResponseData(response.data);
            setIsLoading(false);
        }
        catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
        
    }, [props]);
    // Send request to Ops API
    useEffect(() => {
        fetchDataHandler();
    }, [fetchDataHandler]);

    let content = "No chart available"

    const keysArray = Object.keys(responseData);
    // Validate that data is returned
    if (keysArray.length > 1){
        try{
          const timestamps = responseData.result[0].data[0].timestamps;
          // Validate that timestamps are returned 
          if (timestamps || timestamps.length !== 0){        
          // Convert Unix epoch timestamps to Date format
          const formattedDates = timestamps.map(timestamp => {
              const date = new Date(timestamp);
              return date;
            });
          const values = responseData.result[0].data[0].values;
          // Replace any null values with 0
          //const newValues = values.map(value => value === null ? 0 : value);
          // Remove indices with null values from both formattedDates and values
          const newValues = [];
          const newDates = [];   
          for (let i = 0; i < values.length; i++) {
            if (values[i] !== null) {
              newValues.push(values[i]);
              newDates.push(formattedDates[i]);
            }
          }

          // content = <LineChart xAxis={[{ scaleType: 'point', data:formattedDates}]}
          //     series={[{data:values}]}
          //     width={300}
          //     height={300}
          //     /> 
          content = <SparkLineChart sx={{ mt: 1.5 }} data={newValues} 
          xAxis={{scaleType: 'time', 
          data:newDates, 
          valueFormatter: (value) => value.toISOString().slice(0, 10),}}
          height={40} 
          showTooltip 
          showHighlight />}}
          catch(error){
            content = "No chart available"
          }
    }
    if (isLoading)    {
        return <CircularProgress />
    }

    if (error) {
        content = error;
      }

    return (
      <Box sx={{ flexGrow: 1 }}>
        {content}
      </Box>
    );
  }

  export default MonitorChart;