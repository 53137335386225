import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MonitorChart from "./MonitorChart";

const AppCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Create state to store the response from API call
    const [responseData, setResponseData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState(null);

    const fetchDataHandler = useCallback(async () => {
        try {
            // Include props data in the request parameters
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dynatrace/`, { params: {productId: props.prodId, resultType: 'SUCCESS' } });
            // Set the values from the response
            setResponseData(response.data);
            setIsLoading(false);
        }
        catch (error) {
            //console.error("Error fetching data from Dynatrace", error);
            setError(error.message);
            setIsLoading(false);
        }
        
    }, [props]);
    // Send request to Ops API
    useEffect(() => {
        fetchDataHandler();
    }, [fetchDataHandler]);

    // Determine color of status indicator using health status of monitor
    const indicatorColor = responseData.healthStatus === 'HEALTHY' ? colors.greenAccent[300] : colors.redAccent[500];
    const indicator = (
        <Box
          component="span"
          sx={{ display: 'inline-block', mx: '5px', transform: 'scale(2)', color: indicatorColor }}
        >
          •
        </Box>
      );
    let content = "No url passed"

    const keysArray = Object.keys(responseData);

    if (keysArray.length > 1){
        content = responseData.monitorUrl}
    if (isLoading)    {
        return <CircularProgress />
    }

    if (error) {
        content = error;
      }

    return (
      <Card sx={{ minWidth: 250, backgroundColor:colors.primary[400] } } >
        <CardContent>
          <Typography variant="h5" colors={colors.grey[100]} gutterBottom>
            {props.appId}
          </Typography>
          <Typography variant="body2">
          Response Time:
          <br />
          {`${responseData.responseTime / 1000} s`}
        </Typography >
        <Typography variant="body2">
          Uptime (Week):
        </Typography>
        <MonitorChart prodId={props.prodId}/>
        </CardContent>
        <CardActions>
          <Button size="small" variant="outlined" href={content} target="_blank" rel="noopener noreferrer">
            <Typography variant="h5">
                Status {indicator}
            </Typography>
            </Button>
        </CardActions>
      </Card>
    );
  }

  export default AppCard;