import Header from "../../components/Header";
import { useState, useEffect, useCallback } from "react";
import { tokens } from "../../theme";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

/**
 * This function formats a given date into a string in the format "DD/MM/YY".
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
const formatDateString = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1; // add 1 since month index starts at 0
  let yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return mm + "/" + dd + "/" + yyyy;
};

/**
 * This function gets the date of the last Monday from the current date
 * and returns it as a date object.
 * @returns {Date} The date of the last Monday.
 */
const getLastMonday = () => {
  let date = new Date(); // get today's date
  let day = date.getDay(); // get the current day of the week
  let difference = (day + 6) % 7; // calculate days since last Monday

  date.setDate(date.getDate() - difference); // subtract from today's date

  return date;
};

// Format the date so it can be used by the DataGrid filter
let dateString = getLastMonday();
const formattedLastMonday = dateString.toISOString().slice(0, 10);

// Create array and function to convert numeric grades
const letterGrades = ["A", "B", "C", "D", "E", "F"];
const convertToLetter = (value) => {
  if (value >= 1 && value <= 6) {
    return letterGrades[value - 1];
  }
  // Return N/A values
  return value;
};

/**
 * This function formats a given metric into a material ui datagrid column".
 * @param {string} field - The header to format.
 * @returns {Object} The formatted cell value for the column.
 */
const formatMetricColumn = (field, headerName) => {
  return {
    field: field,
    headerName: headerName,
    renderCell: (params) => {
      const origValue = params.value;
      const letterValue = convertToLetter(origValue); 
      return (
        <span style={{ color: origValue > 3 ? 'red' : 'inherit' }}>
          {letterValue}
        </span>
      );
    },
  };
}
const Releases = () => {
  // Create state to store the response from API call
  const [responseData, setResponseData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);

  const { instance } = useMsal();

  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const activeAccount = instance.getActiveAccount();

  const fetchDataHandler = useCallback(async () => {
    try {
      // Include props data in the request parameters
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/prodreleases`
      );
      // Set the values from the response
      setResponseData(response.data);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }, []);
  // Send request to Ops API
  useEffect(() => {
    fetchDataHandler();
  }, [fetchDataHandler]);

  let content = "No data available";

  const keysArray = Object.keys(responseData);
  // Validate that data is returned
  if (keysArray.length > 1) {
    try {
      const dataGridColumns = [
        {
          field: "date",
          headerName: "Date",
          type: "date",
          width: 100,
          valueGetter: (params) => {
            // Extract date string from the row
            const dateString = params.row.date;

            // Parse the string into a Date object
            return new Date(dateString);
          },
          renderCell: (params) => {
            const dateString = params.row.date;
            const dateObject = new Date(dateString);
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            };
            const displayDate = dateObject.toLocaleDateString("en-US", options);
            return displayDate;
          },
        },
        {
          field: "jira_ticket",
          headerName: "JIRA Ticket",
          width: 100,
          renderCell: (params) => {
            const jiraTicketValue = params.row.jira_ticket;
            if (jiraTicketValue !== "N/A") {
              return (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://dacummins.atlassian.net/browse/${jiraTicketValue}`}
                >
                  {jiraTicketValue}
                </Link>
              );
            }
            return jiraTicketValue;
          },
        },
        { field: "pipeline_name", headerName: "Pipeline Name", width: 250 },
        { field: "project_name", headerName: "Project Name", width: 200 },
        {
          field: "status",
          headerName: "Quality Gate Status",
          renderCell: (params) => {
            const origValue = params.value;
            if (origValue === "ERROR") {
              return (
                <span style={{ color:'red'}}>
                  FAILED
                </span>
              );
            } else if (origValue === "OK") {
              return "PASSED";
            }
            return origValue;
          },
        },
        formatMetricColumn("new_reliability_rating", "New Reliability Rating"),
        formatMetricColumn("new_security_rating", "New Security Rating"),
        formatMetricColumn("new_maintainability_rating", "New Maintainability Rating"),
        formatMetricColumn("sqale_rating","Maintainability Rating"),
        { field: "new_coverage", 
          headerName: "New Coverage",
          renderCell: (params) => {
            const origValue = params.value;
            return (
              <span style={{ color: origValue < 70 ? 'red' : 'inherit' }}>
                {origValue}
              </span>
            );
          }
       },
      ];
      const dataGridRows = responseData.map((row, index) => ({
        id: index,
        ...row,
      }));
      content = (
        <DataGrid
          getRowHeight={() => "auto"}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              // Forced to use important since overriding inline styles
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              // Forced to use important since overriding inline styles
              maxHeight: "168px !important",
            },
          }}
          columns={dataGridColumns}
          rows={dataGridRows}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    field: "date",
                    operator: "onOrAfter",
                    value: formattedLastMonday,
                  },
                ],
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20]}
        />
      );
    } catch (error) {
      content = "No chart available";
    }
  }
  if (isLoading) {
    content = <CircularProgress />;
  }

  if (error) {
    return error;
  }
  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Box margin="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header
                title="RELEASES"
                subtitle={`Releases for the Week of ${formatDateString(
                  dateString
                )}`}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="90%"
            >
              {content}
            </Box>
          </Box>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography paddingBottom variant="h4" color={colors.grey[100]}>
            Please log in to view resources.
          </Typography>
        </Box>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Releases;
