import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AppCard from "../../components/AppCard";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Box margin="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="NOC DASHBOARD" subtitle="DSE-DIS-DS" />
            </Box>

            {/* GRID*/}
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="200px"
              gap="20px"
            >
              {/* ROW 1 */}
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AppCard appId="Preventech" prodId="preventech-prod" />
              </Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AppCard appId="PowerCommandCloud" prodId="pcc-prod" />
              </Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AppCard
                  appId="ConnectedSolutions"
                  prodId="connectedsolutions-prod"
                />
              </Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AppCard appId="Parts.cummins.com" prodId="parts-prod" />
              </Box>

              {/* ROW 2 */}
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AppCard appId="QuickServe Online" prodId="qsol-prod" />
              </Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>

              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>

              {/* ROW 3 */}
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>
              <Box
                gridColumn="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Box>
            </Box>
          </Box>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography paddingBottom variant="h4" color={colors.grey[100]}>
            Please log in to view resources.
          </Typography>
        </Box>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Dashboard;
