import { Box, IconButton, useTheme, Button } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest, b2cPolicies } from "../../authConfig";

const Topbar = () => {
  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginPopup = () => {
    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: "/redirect",
      })
      .catch((error) => console.log(error));
  };

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/", // redirects the top level app after logout
    });
  };
  // Get Material UI theme
  const theme = useTheme();
  // Pass color mode from MUI to tokens
  const colors = tokens(theme.palette.mode);
  // Toggle different states for color mode
  const colorMode = useContext(ColorModeContext);
  // Use Material UI Box component to directly write css
  return (
    <>
      <Box display="flex" justifyContent="flex-end" padding={2}>
        <Box display="flex">
          {/* React context of function to change from dark to light */}
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
      </Box>
      <AuthenticatedTemplate>
        <Box display="flex" justifyContent="flex-end" paddingRight={2}>
          <Button onClick={handleLogoutPopup}>Log Out</Button>
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box display="flex" justifyContent="flex-end" paddingRight={2}>
          <Button onClick={handleLoginRedirect}>Log In</Button>
        </Box>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Topbar;
